import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import ReactTooltip from "react-tooltip";

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

function Mobile({ data }) {
    let d = data.contentfulMobile;
    let content = d.content;

    let badge = (
        <div className="flex items-center space-x-2">
            <a href="https://play.google.com/store/apps/details?id=id.ngorder.ngorder_app&utm_source=landingpage&utm_campaign=download_app_android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                    alt="Get it on Google Play"
                    src="../icons/mobile-badge/google-play-badge.svg"
                    className="h-12"
                />
            </a>
            <a href="https://apps.apple.com/id/app/ngorder/id1548421949">
                <img
                    alt="Download on the App Store"
                    src="../icons/mobile-badge/app-store-badge.svg"
                    className="h-12"
                />
            </a>
        </div>
    );

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <ReactTooltip />

            <section className="py-4 md:py-16 md:-mt-24 bg-secondary dark:bg-gray-800">
                <div className="flex flex-col md:flex-row w-full max-w-6xl mx-auto px-4 py-8 md:pt-16 md:pb-4 text-left">
                    <div className="w-full md:w-1/2">
                        <h2 className="text-sm uppercase tracking-widest">
                            {d.label}
                        </h2>
                        <h1 className="text-3xl md:text-5xl mt-4 leading-tight font-display">
                            {d.content.title}
                        </h1>
                        <p className="text-lg md:text-2xl mt-10">
                            {parse(d.content.subtitle)}
                        </p>

                        <div className="flex mt-10 space-x-8">
                            <div>
                                <img
                                    src="../images/onlink_to_dp2mmu.svg"
                                    alt="qrcode"
                                    className="w-28 h-28"
                                />
                            </div>
                            {badge}
                        </div>
                    </div>
                    <div className="w-0 md:w-1/12"></div>
                    <div className="w-3/4 md:w-5/12 mx-auto mt-6 md:mx-0 md:mt-0">
                        <img src="../images/mobile-1.webp" alt="preview apps" />
                    </div>
                </div>
            </section>

            <section className="z-0 px-4 md:px-14 py-20 h-auto">
                <CarouselProvider
                    isIntrinsicHeight={true}
                    totalSlides={3}
                    isPlaying={true}
                    lockOnWindowScroll={true}
                    className="w-full max-w-6xl mx-auto border dark:border-gray-700 rounded-2xl relative overflow-hidden"
                >
                    <Slider>
                        {content.slider.map((item, i) => (
                            <Slide
                                className="bg-white dark:bg-gray-700"
                                key={i}
                            >
                                <div className="flex text-left h-auto items-center">
                                    <div className="flex-1 p-2 md:p-8">
                                        <img src={`../images/${item.img}`} />
                                    </div>
                                    <div className="flex-1 p-2 md:px-8">
                                        <h2 className="text-xl md:text-4xl leading-tight mb-4 md:mb-6 mt-2 font-display pr-6">
                                            {item.title}
                                        </h2>

                                        <p className="max-w-md text-gray-700 dark:text-gray-200 text-normal md:text-xl">
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>
                            </Slide>
                        ))}
                    </Slider>

                    <div className="flex w-full absolute bottom-0 left-1/2 z-50 md:pl-6">
                        <DotGroup className="mb-1 md:mb-4">
                            <Dot
                                className="w-3 h-3 rounded-full mx-2 bg-gray-700 dark:bg-gray-400 focus:outline-none opacity-25"
                                slide={0}
                            ></Dot>
                            <Dot
                                className="w-3 h-3 rounded-full mx-2 bg-gray-700 dark:bg-gray-400 focus:outline-none opacity-25"
                                slide={1}
                            ></Dot>
                            <Dot
                                className="w-3 h-3 rounded-full mx-2 bg-gray-700 dark:bg-gray-400 focus:outline-none opacity-25"
                                slide={2}
                            ></Dot>
                        </DotGroup>
                    </div>
                </CarouselProvider>
            </section>

            <section
                id="table"
                className="bg-primary dark:bg-gray-700"
                style={{
                    backgroundImage: `url(../images/mobile-footer.webp)`,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `auto 500px`,
                    backgroundPosition: `100% 0`,
                }}
            >
                <div className="max-w-6xl mx-auto py-32 px-4 md:px-0">
                    <div className="max-w-4xl mx-auto text-center flex flex-col items-center">
                        <h4 className="text-3xl md:text-4xl mb-2 md:mb-4 leading-tight font-display text-white">
                            {content.cta.title}
                        </h4>

                        <p className="text-lg mb-6 md:mb-10 text-white">
                            {parse(content.cta.description)}
                        </p>
                        {badge}
                    </div>
                </div>
            </section>
        </Layout>
    );
}

Mobile.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulMobile {
            label
            content {
                title
                subtitle
                slider {
                    title
                    desc
                    img
                }
                cta {
                    title
                    img
                    description
                }
            }
            seo {
                title
                description
                keywords
            }
        }
    }
`;

export default Mobile;
